<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card color="#fafafa">
            <v-toolbar
                dense
                color="grey lighten-2"
            >
                <v-btn
                    icon
                    @click="dialog = false"
                >
                    <v-icon>fa-times</v-icon>
                </v-btn>
                <v-toolbar-title>Report Preview</v-toolbar-title>
            </v-toolbar>
            <br>
            <v-card-text>
                <report-view :report-id="reportId" :report-data="reportData" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import ReportView from '../Viewer/Viewer.vue';

    export default {
        name: 'ReportBuilderPreview',

        components: {
            ReportView,
        },

        props: {
            reportId: {
                type: String,
                default: undefined,
            },
            reportData: {
                type: Object,
                required: true,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            dialog: false,
        }),

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.dialog = newValue;
                },
            },
            dialog(newValue) {
                this.$emit('input', newValue);
            },
        },
    };
</script>
