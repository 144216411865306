<template>
    <request :request="request" @on-success="onTableRequestSuccess">
        <v-card>
            <v-card-title>Reports</v-card-title>
            <v-simple-table class="text-left" v-if="hasReports">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Client</th>
                            <th>Created at</th>
                            <th>Created By</th>
                            <th>Updated at</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in reports"
                            :key="item.id"
                            @click="viewReport(item.id)"
                            style="cursor: pointer"
                        >

                            <td>{{ item.title }}</td>
                            <td>
                                <v-chip
                                    class="ma-2"
                                    color="grey darken-2"

                                    small
                                    dark
                                    pill
                                >
                                    <b>{{ item.client }}</b>
                                </v-chip>
                            </td>
                            <td>{{ item.createdAt }}</td>
                            <td>{{ item.createdBy }}</td>
                            <td>{{ item.updatedAt }}</td>
                            <td>
                                <v-btn
                                    v-if="userCanEdit"
                                    icon
                                    small
                                    :to="{ name: 'manage-report-builder', params: { reportId: item.id }}">
                                    <v-icon small>$edit</v-icon>
                                </v-btn>
                                <i-delete-btn
                                    v-if="userCanDelete"
                                    title="Are you sure to delete the report?"
                                    :payload="{reportId: item.id, reportTitle: item.title}"
                                    @on-confirm="deleteReport"/>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <preview v-model="preview" :report-data="previewReportData" :reportId="previewReportId" />
    </request>
</template>

<script>
    import Preview from './Preview.vue';
    import ReportModel from './ReportModel';
    import ReportAPI from './webapi';

    export default {
        name: 'ReportsTable',

        components: {
            Preview,
        },

        data: () => ({
            request: () => ReportAPI.listReports(),
            reports: [],
            preview: false,
            previewReportId: undefined,
            previewReportData: undefined,
        }),

        computed: {
            hasReports() {
                return this.reports.length > 0;
            },
            userCanEdit() {
                return this.$store.getters['auth/userIsStaff'];
            },
            userCanDelete() {
                return this.$store.getters['auth/userIsStaff'];
            },
        },

        methods: {
            onTableRequestSuccess(data) {
                this.reports = data.map((item) => new ReportModel(item));
            },
            deleteReport(obj) {
                const { reportId, reportTitle } = obj;
                ReportAPI.deleteReport(reportId).then(() => {
                    // Rerequest a list of reports.
                    this.$helpers.notifySuccess(`Report ${reportTitle} successfuly deleted.`);
                    this.request = () => ReportAPI.listReports();
                }).catch(() => {
                    this.$helpers.notifyError(`Report ${reportTitle} was not deleted.`);
                });
            },
            viewReport(reportId) {
                ReportAPI.getReport(reportId).then((response) => {
                    this.previewReportId = reportId;
                    this.previewReportData = response;
                    this.preview = true;
                }).catch((err) => {
                    console.error(err);
                    this.$helpers.notifyError('Cannot retrieve report.');
                });
            },
        },
    };
</script>
