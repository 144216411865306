<template>
    <v-row>
        <v-col cols=12>
            <v-btn color="primary" :to="{name: 'manage-report-builder-new'}"><v-icon small left>$add</v-icon>New Report</v-btn>
        </v-col>
        <v-col>
            <report-table />
        </v-col>
    </v-row>
</template>

<script>
    import ReportTable from '@/components/Report/Builder/Table.vue';

    export default {
        name: 'ReportHomePage',

        components: {
            ReportTable,
        },

        data: () => ({

        }),
    };
</script>
