import dateutils from '@/utils/dateutils';

export default class ReportModel {
    private report: Record<string, any>;

    constructor(report: Record<string, any>) {
        this.report = report;
    }

    get id() : string {
        return this.report.id;
    }

    get title() : string {
        return this.report.report.title;
    }

    get client() : string {
        return this.report.report.meta?.client?.name || this.report.report.client;
    }

    get createdAt() : string {
        return dateutils.datetime(this.report.created_at);
    }

    get createdBy() : string {
        return `${this.report.created_by.first_name} ${this.report.created_by.last_name}`;
    }

    get updatedAt() : string {
        return dateutils.datetime(this.report.updated_at);
    }
}
