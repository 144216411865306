import constants from '@/constants';
import webapi from '@/core/webapi';

const BASE_URI = `${constants.API_BASE}/report/manage`;
const BASE_UTILS = `${constants.API_BASE}/report/utils`;

export default class ReportAPI {
    static async getMetadata() : Promise<any> {
        const request = await Promise.all([
            ReportAPI.getComponents(),
        ]);
        return request;
    }

    static async getComponents() : Promise<any> {
        const uri = `${BASE_URI}/components`;
        return webapi.get({ uri });
    }

    static async listReports() : Promise<any> {
        const uri = `${BASE_URI}`;
        return webapi.get({ uri });
    }

    static async createReport(payload: Record<string, any>) : Promise<any> {
        const uri = `${BASE_URI}`;
        const body = { report: payload };
        return webapi.post({ uri, body });
    }

    static async getReport(reportId: String) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}`;
        return webapi.get({ uri });
    }

    static async updateReport(reportId: String, payload: Record<string, any>) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}`;
        const body = { report: payload };
        return webapi.put({ uri, body });
    }

    static async deleteReport(reportId: String) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}`;
        return webapi.delete({ uri });
    }

    static async listClientWidgets(clientKey: String) : Promise<any> {
        const uri = `${BASE_UTILS}/widgets/${clientKey}`;
        return webapi.get({ uri });
    }

    static async sendEmailNotification(
        reportId: String, mode: String, messageBefore: String,
    ) : Promise<any> {
        const uri = `${BASE_URI}/${reportId}/email-notification`;
        const body = {
            notification_mode: mode,
            message_before: messageBefore,
        };

        return webapi.post({ uri, body });
    }
}
